import React, { ReactElement } from 'react';
import './App.css';
import { Box } from '@mui/material';
import Content from "./components/Content";
import UnderConstruction from './components/UnderConstruction';
import styled from 'styled-components';

const App = (): ReactElement => (
  <StyledBox>
    <UnderConstruction />
    {/*<Content />*/}
  </StyledBox>
);

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(165,162,226);
  background: linear-gradient(218deg, rgba(165,162,226,1) 0%, rgba(174,157,193,1) 35%, rgba(210,210,210,1) 100%);
`;

export default App;
