import { Box } from '@mui/material';
import Footer from './Footer';
import styled from 'styled-components';
import { chaoticOrbit } from 'ldrs';

const UnderConstruction = () => {
  chaoticOrbit.register();

  return (
    <StyledBox>
      <div>🛠</div>
      <h1 style={{ fontFamily: 'monospace' }}>️under construction</h1>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <l-chaotic-orbit
          size="50"
          speed="1.5"
          color="#37333b"
        ></l-chaotic-orbit>
      </Box>
      <Footer />
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  text-align: center;
  padding: 3rem;
`;

export default UnderConstruction;
