import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Divider } from '@mui/material';

const Footer = () => {
  return (
    <StyledFooter>
      <Divider />
      <StyledName>marcel partosoebroto</StyledName>
    </StyledFooter>
  );
};

const StyledFooter = styled(motion.div)`
  color: #37333b;
  text-align: center;
  padding: 20px;
`;

const StyledName = styled(motion.div)`
  font-size: 12px;
  font-family: monospace;
  margin: 14px 0;
`;

export default Footer;
